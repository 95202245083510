<template>
  <div class="row">
    <div class="col">
      <div class="header" v-if="currentQuestion">Aufgabe {{ currentQuestion.number }}</div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="p-3 border aufgabe">
          <p v-if="currentQuestion">{{ currentQuestion.question }}</p>
          <img v-if="currentQuestion && currentQuestion.image" :src="currentQuestion.image" class="img-fluid mt-3" />
          <div class="p-1" id="formular">
            <label for="exampleFormControlInput1" class="form-label"></label>
            <input v-if="currentQuestion" type="text" v-model="userAnswer" class="form-control" placeholder="Antwort hier eingeben">
          </div>
          <div class="d-flex justify-content-end">
            <button v-if="currentQuestion" @click="checkAnswer" class="circle-button">
              <i class="bi bi-check-lg fs-2"></i>
            </button>
            <p v-else>Fragen werden geladen...</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Fehler Modal -->
  <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
    <div class=" modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="errorModalLabel">Leider Falsch!</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Versuch es nochmal, achte auf korrekte Schreibweise.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Schließen</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Erfolg Modal -->
  <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
    <div class=" modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="successModalLabel">Herzlichen Glückwunsch!</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Alle Fragen sind richtig beantwortet!</p>
          <br>
          <p>Geht zurück zum "MSPACE"</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="$router.push('/result')">weiter</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Gesprächsthemen Modal -->
  <div class="modal fade" id="themenModal" tabindex="-1" aria-labelledby="themenModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="themenModalLabel">Gesprächsthemen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul>
            <li v-for="thema in randomThemen" :key="thema">{{ thema }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import * as bootstrap from 'bootstrap';

export default {
  name: 'FragenComponent1',
  data() {
    return {
      questions: [],
      currentQuestionIndex: 0,
      userAnswer: '',
      themen: [],
      randomThemen: []
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
  },
  created() {
    this.loadQuestions();
    this.loadThemen();
  },
  methods: {
    async loadQuestions() {
      try {
        const response = await fetch('/Route1.1.json'); // Pfad zur JSON-Datei
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        this.questions = await response.json();
        this.startThemenTimer();
      } catch (error) {
        console.error('Fehler beim Laden der Fragen:', error);
      }
    },
    async loadThemen() {
      try {
        const response = await fetch('/Themen.json'); // Pfad zur JSON-Datei
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        this.themen = await response.json();
      } catch (error) {
        console.error('Fehler beim Laden der Gesprächsthemen:', error);
      }
    },
    checkAnswer() {
      if (this.userAnswer.trim().toLowerCase() === this.currentQuestion.correct.toLowerCase()) {
        if (this.currentQuestionIndex < this.questions.length - 1) {
          this.currentQuestionIndex++;
          this.userAnswer = '';
          this.startThemenTimer();
        } else {
          const successModal = new bootstrap.Modal(document.getElementById('successModal'));
          successModal.show();
        }
      } else {
        const modal = new bootstrap.Modal(document.getElementById('errorModal'));
        modal.show();
      }
    },
    startThemenTimer() {
      setTimeout(() => {
        this.showThemenModal();
      }, 60000);
    },
    showThemenModal() {
      this.randomThemen = this.getRandomThemen(3);
      const themenModal = new bootstrap.Modal(document.getElementById('themenModal'));
      themenModal.show();
    },
    getRandomThemen(count) {
      const shuffled = this.themen.Gesprächsthemen.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    }
  }
};
</script>

<style scoped>
.header {
  background-color: #c50e1f;
  padding: 30px;
  color: white;
  font-family: 'Fredoka One', sans-serif;
  font-size: 32px;
}

.col {
  text-align: center;
}

.circle-button {
  background-color: #c50e1f;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}

.aufgabe {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 5px;
  margin-top: 10px;
  text-align: justify;
}
</style>