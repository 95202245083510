<template>
  <div class="row">
    <div class="col">
      <div class="header">Spielregeln</div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-9">
        <div class="p-3 rules">
          <b>Die Spielregeln sind simpel</b>, der Spaß steht wie gesagt an erster Stelle. Solltet ihr also auf euren Wegen
          weitere interessante Orte finden, besucht sie gerne. Um das Ganze interessanter zu gestalten, versucht doch
          bitte, die Lösungen ohne Zuhilfenahme eurer Handys zu finden. Fotos könnt ihr jedoch gern jederzeit machen. Für
          Fragen stehen euch die Supervisoren per Instagram zur Verfügung, checkt also auch gern die Instagram-Seiten ab.
          Falls ihr mal kein Gesprächsthema habt, wird immer wieder ein Pop-up-Fenster auftauchen, das euch mit Anregungen
          versorgt. Wir wünschen viel Erfolg und vor allem Spaß.
        </div>
      </div>
      <div class="col-3 sidebar">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href="https://minitiative.org"> <img src="../assets/Min.png" alt="Logo" class="img-fluid">
              </a>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href="https://www.instagram.com/minitiative.berlin/"> <img src="../assets/InstaLogo.png" alt="Logo"
                  class="img-fluid">
              </a>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href="https://mentoring.eecs.tu-berlin.de/de/"> <img src="../assets/Mentoring.png" alt="Logo"
                  class="img-fluid">
              </a>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href=" https://www.instagram.com/mentoring.eecs/"> <img src="../assets/InstaLogo.png" alt="Logo"
                  class="img-fluid">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 button-section">
        <button class="btn btn-block circle-button" @click="$router.push('/gruppe')">
          <i class="bi bi-arrow-right-circle text-white fs-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpielregelnComponent',
};
</script>

<style scoped>
.header {
  background-color: #c50e1f;
  padding: 30px;
  color: white;
  font-family: 'Fredoka One', sans-serif;
  font-size: 32px;
}
.row:last-child{
    margin-bottom: 20px;
}

.rules {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 5px;
  text-align: justify;
}

.rules,
.button-section {
  margin-top: 10px;
}

.col {
  text-align: center;
}

.button-section {
  display: flex;
  justify-content: center;
  align-items: end;
}

.circle-button {
  background-color: #c50e1f;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
}

#sidebar {
  min-width: 55px;
  max-width: 55px;
  max-height: 55px;
  min-height: 55px;
  margin-top: 10px;
}
.link {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 5px;
}
</style>

