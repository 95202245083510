import { createRouter, createWebHistory } from 'vue-router'
import StartseiteComponent from './components/StartseiteComponent.vue'
import SpielregelnComponent from './components/SpielregelnComponent.vue'
import FragenComponent1 from './components/FragenComponent1.vue';
import FragenComponent2 from './components/FragenComponent2.vue';
import FragenComponent3 from './components/FragenComponent3.vue';
import FragenComponent4 from './components/FragenComponent4.vue';
import FragenComponent5 from './components/FragenComponent5.vue';
import FragenComponent6 from './components/FragenComponent6.vue';
import FragenComponent7 from './components/FragenComponent7.vue';
import FragenComponent8 from './components/FragenComponent8.vue';
import FragenComponent9 from './components/FragenComponent9.vue';
import FragenComponent10 from './components/FragenComponent10.vue';
import FragenComponent11 from './components/FragenComponent11.vue';
import FragenComponent12 from './components/FragenComponent12.vue';
import GruppeComponent from './components/GruppeComponent.vue'
import ResultComponent from './components/ResultComponent.vue'

const routes = [
    {
        path: '/',
        redirect: '/startseite'
    },
    {
        path: '/startseite',
        name: 'Startseite',
        component: StartseiteComponent
    },
    {
        path: '/spielregeln',
        name: 'Spielregeln',
        component: SpielregelnComponent
    },
    {
        path: '/fragen1',
        name: 'Fragen1',
        component: FragenComponent1
      },
      {
        path: '/fragen2',
        name: 'Fragen2',
        component: FragenComponent2
      },
      {
        path: '/fragen3',
        name: 'Fragen3',
        component: FragenComponent3
      },
      {
        path: '/fragen4',
        name: 'Fragen4',
        component: FragenComponent4
      },
      {
        path: '/fragen5',
        name: 'Fragen5',
        component: FragenComponent5
      },
      {
        path: '/fragen6',
        name: 'Fragen6',
        component: FragenComponent6
      },
      {
        path: '/fragen7',
        name: 'Fragen7',
        component: FragenComponent7
      },
      {
        path: '/fragen8',
        name: 'Fragen8',
        component: FragenComponent8
      },
      {
        path: '/fragen9',
        name: 'Fragen9',
        component: FragenComponent9
      },
      {
        path: '/fragen10',
        name: 'Fragen10',
        component: FragenComponent10
      },
      {
        path: '/fragen11',
        name: 'Fragen11',
        component: FragenComponent11
      },
      {
        path: '/fragen12',
        name: 'Fragen12',
        component: FragenComponent12
      },
    {
        path: '/result',
        name: 'Result',
        component: ResultComponent
    },
    {
        path: '/gruppe',
        name: 'Gruppe',
        component: GruppeComponent
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/startseite'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
