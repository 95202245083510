<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="header">Routen Auswahl</div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="p-3" id="motivation">
                        <b>Los geht es!</b>
                        <br>
                        Es gibt 12 Roten zur Auswahl, sprecht euch bitte mit den andern Gruppen ab, wer welche Route wählt.
                    </div>
                </div>
                <div class="col-6 button-section">
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen1')">
                        Route 1
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen2')">
                        Route 2
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen1')">
                        Route 3
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen4')">
                        Route 4
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen5')">
                        Route 5
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen6')">
                        Route 6
                    </button>
                </div>
                <div class="col-6 button-section2">
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen7')">
                        Route 7
                    </button>

                    <button class="btn btn-block circle-button" @click="$router.push('/fragen8')">
                        Route 8
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen9')">
                        Route 9
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen10')">
                        Route 10
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen11')">
                        Route 11
                    </button>
                    <button class="btn btn-block circle-button" @click="$router.push('/fragen12')">
                        Route 12
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GruppeComponent',
};
</script>

<style scoped>
.header {
    background-color: #c50e1f;
    padding: 30px;
    color: white;
    font-family: 'Fredoka One', sans-serif;
    font-size: 32px;
    text-align: center;
}

#motivation {
    background-color: rgba(217, 217, 217, 1);
    margin-top: 10px;
    border-radius: 5px;
    text-align: justify;
}

.button-section,
.button-section2 {
    margin-top: 10px;
}

.circle-button {
    background-color: #c50e1f;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    color: white;
    font-size: 16px;
}
</style>

  
  