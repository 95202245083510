<template>
  <div class="row">
    <div class="col">
      <div class="header">TU-Schnitzeljagd</div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-9">
        <div class="p-3 border welcome-section">
          <span> <b>Herzlich willkommen zur Uni-Schnitzeljagd!</b></span>
          <br>
          Wir haben für euch eine kleine Tour durch Berlin vorbereitet. Ziel ist es, euch besser kennenzulernen und erste
          Kontakte zu knüfen. Der Spaß an der Sache steht also im Vordergrund, demzufolge ist das Ganze auch kein
          Wettrennen. Wir haben Orte ausgewählt, die nicht allzu weit weg sind, aber dennoch für jederman interessant sein
          sollten. Wir wünschen viel Spaß. 
          <br>Helft euch untereinander. :)
        </div>
      </div>
      <div class="col-3 sidebar">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href="https://minitiative.org"> <img src="../assets/Min.png" alt="Logo" class="img-fluid">
              </a>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href="https://www.instagram.com/minitiative.berlin/"> <img src="../assets/InstaLogo.png" alt="Logo"
                  class="img-fluid">
              </a>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href="https://mentoring.eecs.tu-berlin.de/de/"> <img src="../assets/Mentoring.png" alt="Logo"
                  class="img-fluid">
              </a>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-2 border link" id="sidebar">
              <a href=" https://www.instagram.com/mentoring.eecs/"> <img src="../assets/InstaLogo.png" alt="Logo"
                  class="img-fluid">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Button -->
    <div class="row">
      <div class="col">
        <button class="btn btn-block" @click="$router.push('/spielregeln')">
          Start!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartseiteComponent',
};
</script>

<style scoped>
.header {
  background-color: #c50e1f;
  padding: 30px;
  color: white;
  font-family: 'Fredoka One', sans-serif;
  font-size: 32px;
}

.welcome-section,
.sidebar {
  margin-top: 10px;
}

.welcome-section {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 5px;
  text-align: justify;
}

.col {
  text-align: center;
}

.btn-block {
  padding: 15px;
  background-color: #c50e1f;
  margin-top: 10px;
  font-family: 'Fredoka One', sans-serif;
  font-size: 20px;
  color: white;
  border-radius: 10px;
}

#sidebar {
  min-width: 55px;
  max-width: 55px;
  max-height: 55px;
  min-height: 55px;
}

.link {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 5px;
}
</style>

