<template>
    <div class="row">
      <div class="col">
        <div class="header">Geschafft!</div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="p-3 end-text">
            <b>Vielen Dank, </b>dass ihr an der Schnitzeljagd teilgenommen habt. Wir hoffen, ihr hattet Spaß beim Erkunden und habt gleichzeitig Berlin und euch untereinander besser kennenlernen können. Falls ihr es nicht schon getan habt, tauscht euch doch gern aus. 
             <br>Gute Kontakte in der Uni sind wirklich von Vorteil.
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <div class="button-section">
            <button class="btn btn-block circle-button fs-2 text-white" @click="$router.push('/startseite')">
              <i class="bi bi-house-door"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ResultComponent',
  };
  </script>
  
  <style scoped>
  .header {
    background-color: #c50e1f;
    padding: 30px;
    color: white;
    font-family: 'Fredoka One', sans-serif;
    font-size: 32px;
    text-align: center;
  }
  .end-text {
    border-radius: 5px;
    background-color: rgba(217, 217, 217, 1);
    text-align: justify;
    margin-top: 10px;
  }
  .button-section {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end; /* Positioniert den Button am rechten Rand */
    align-items: center;
  }
  .circle-button {
    background-color: #c50e1f;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
  }
  
  </style>
  